body,
#root {
  height: 100%;
}

.main {
  height: 100%;
  padding: 1rem;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgb(49 54 68 / 5%), 0 5px 20px rgb(49 54 68 / 8%);
}

.logs {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.log {
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

​.streaming {
  white-space: pre-wrap;
}

.streaming > :not(ol):not(ul):not(pre):last-child:after,
.streaming > ol:last-child li:last-child:after,
.streaming > pre:last-child code:after,
.streaming > ul:last-child li:last-child:after {
  -webkit-animation: blink 1s steps(5, start) infinite;
  animation: blink 1s steps(5, start) infinite;
  content: '▋';
  margin-left: 0.25rem;
  vertical-align: baseline;
}

.chat-bubble-left {
  align-self: flex-start;
  background-color: #efefef;
  color: #000000;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}

.chat-bubble-right {
  align-self: flex-end;
  background-color: #5358a9;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px;
}

.title {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
}

.title img {
  width: 200px;
  height: auto;
}